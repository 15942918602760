<template>
  <div class="form-control form-control--icon" :class="{ 'form-control--invalid': validateError }">
    <div class="form-control_relative">
      <label
        class="form-control__label"
        :class="{ 'form-control__label--valid': date || focusDate }"
        >{{ placeholder }}
        <span class="form-control__label_required" v-if="required">*</span></label
      >
      <DatePicker
        v-if="time"
        name="date"
        class="collect-datepicker"
        use12h
        show-hour
        type="datetime"
        :format="format"
        v-model="date"
        value-type="YYYY-MM-DD HH:mm"
        input-class="form-control__input"
        :disabled="disabled"
        :disabled-date="date => createDisbledDate(date, 'YYYY-MM-DD')"
        :disabled-time="date => createDisbledDate(date, 'YYYY-MM-DD HH:mm')"
        @focus="focusDate = true"
        @blur="focusDate = false"
      >
        <i slot="icon-calendar">
          <IconCalendar />
        </i>
      </DatePicker>
      <DatePicker
        v-else
        class="collect-datepicker"
        name="date"
        type="date"
        :format="format"
        v-model="date"
        value-type="YYYY-MM-DD"
        input-class="form-control__input"
        :disabled="disabled"
        :disabled-date="date => createDisbledDate(date, 'YYYY-MM-DD')"
        @focus="focusDate = true"
        @blur="focusDate = false"
      >
        <i slot="icon-calendar">
          <IconCalendar />
        </i>
      </DatePicker>
    </div>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import IconCalendar from "@/components/icons/IconCalendar.vue";
export default {
  props: {
    value: { type: String },
    placeholder: { type: String },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    format: { type: String, default: "YYYY-MM-DD hh:mm a" },
    startDate: { type: [String, Boolean], default: false },
    endDate: { type: [String, Boolean], default: false },
    validateError: { type: String, default: "" },
    time: { type: Boolean, default: true },
  },
  components: { DatePicker, IconCalendar },
  data() {
    return {
      date: null,
      focusDate: false,
    };
  },
  created() {
    this.date = this.value;
  },
  watch: {
    value(val) {
      this.date = val;
    },
    date(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    createDisbledDate(date, format) {
      if (this.startDate) {
        return this.$moment(date).format(format) < this.$moment(this.startDate).format(format);
      } else if (this.endDate) {
        return this.$moment(date).format(format) > this.$moment(this.endDate).format(format);
      }
      return false /* this.$moment(date).format(format) < this.$moment().tz("America/New_York").format(format) */;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  &_relative {
    position: relative;
    height: 9rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
    }
  }
  &__label {
    width: calc(100% - 7.1rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &--valid {
      top: 0;
      width: auto;
      z-index: 1;
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
  &--invalid {
    .form-control_relative {
      border-color: $error;
    }
  }
}
</style>
