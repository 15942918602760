<template>
  <div class="form-control" :class="{ 'form-control--invalid': validateError }">
    <div class="form-control__textarea-wrapper">
      <span v-if="maxLength" class="form-control__counter"
        >{{ element.length || 0 }}/{{ maxLength }}</span
      >
      <textarea
        class="form-control__textarea"
        :class="{ 'form-control__textarea--count': maxLength }"
        ref="textarea"
        required
        :placeholder="placeholder"
        :value="element"
        @input="
          changeHeight();
          $emit('input', $event.target.value);
        "
      /><label class="form-control__label">
        {{ placeholder }}
        <span class="form-control__label_required" v-if="required">*</span>
      </label>
    </div>
    <p class="form-control__error">{{ validateError }}</p>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: { type: String },
    element: { type: String },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    maxLength: { type: [String, Number], required: false },
  },
  data() {
    return {
      showText: true,
    };
  },
  mounted() {
    this.changeHeight();
  },
  methods: {
    changeHeight() {
      let currenTextarea = this.$refs.textarea;
      currenTextarea.height = "auto";
      currenTextarea.style.height = currenTextarea.scrollHeight + "px";
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  width: 100%;
  position: relative;
  margin-top: 10px;
  &__icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 30px;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__textarea {
    height: auto;
    min-height: 12rem;
    padding: 2.4rem 2.4rem 2.4rem 2.7rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @include type();
    overflow: hidden;
    color: $black;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &-wrapper {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }
    &::-moz-placeholder {
      opacity: 0;
    }
    &::-ms-input-placeholder {
      opacity: 0;
    }
    &::-webkit-input-placeholder {
      opacity: 0;
    }
  }
  &__textarea:focus ~ &__label,
  &__textarea:valid ~ &__label {
    top: 0;
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
  &__counter {
    position: absolute;
    right: 16px;
    top: 46px;
    font-family: $fontFrutiger;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3;
  }
  &--invalid {
    .form-control__textarea {
      border-color: $error;
    }
  }
  &--simple {
    .form-control__label {
      position: relative;
      display: block;
      width: auto;
      top: initial;
      left: initial;
      transform: initial;
      background: transparent;
      color: $white;
      padding: 0;
      margin-bottom: 13px;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      height: 18px;
      &_required {
        font-family: $fontFrutiger;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: $white;
      }
    }
    .form-control__textarea {
      min-height: 19.5rem;
      font-family: $fontFrutiger;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: $white;
      border-radius: 4px;
      background-color: rgba($color: $white, $alpha: 0.1);
      padding: 15px;
      &--count {
        padding-right: 10rem;
      }
      &::-moz-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: $white;
        opacity: 1;
      }
      &::-webkit-input-placeholder {
        color: $white;
        opacity: 1;
      }
    }
  }
}
</style>