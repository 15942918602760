<template>
  <div class="form-control" :class="{ 'form-control--disabled': disabled }">
    <div class="form-control__input">
      {{ text }}
      <div class="switcher" @click="disabled ? false : $emit('input', +!selected)">
        <label
          class="switcher__label"
          :class="{ disabled: disabled, 'switcher__label--actiive': selected }"
        >
          <span> </span>
          <span> </span>
        </label>
      </div>
    </div>
    <label class="form-control__label"
      >{{ placeholder }} <span class="form-control__label_required" v-if="required">*</span></label
    >
  </div>
</template>
<script>
export default {
  props: {
    type: { type: String, required: false },
    items: { type: Array },
    selected: { type: [Boolean, Number, String], default: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
  },
  data() {
    return {
      text: "",
    };
  },
  created() {
    for (let i of this.items) {
      if (this.selected === +i.id) {
        this.text = i.title;
        return;
      }
    }
  },
  watch: {
    selected(val) {
      for (let i of this.items) {
        if (val === +i.id) {
          this.text = i.title;
          return;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  &__label {
    width: calc(100% - 5.1rem);
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      font-size: 18px;
      font-weight: 600;
      color: $error;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9rem;
    padding: 0 2.4rem 0 2.7rem;
    border: 0.5px solid $lightGrey;
    border-radius: 12px;
    @include type();
    color: $lightBlack;
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: $white;
      opacity: 0;
    }
    &:-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &::-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &:disabled {
      opacity: 0.6;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      height: 7rem;
      font-size: 14px;
    }
  }
  &__input:focus ~ &__label,
  &__input:valid ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    top: 0;
    width: auto;
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $error;
      color: $error;
    }
  }
  .icon {
    display: inline-flex;
    width: 1.85rem;
    height: 1.85rem;
    transition: all 0.3s ease-in-out;
    transform: rotate(-180deg);
    &--open {
      transform: rotate(0);
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 14px;
      height: 14px;
    }
  }
  &__items {
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 2;
    padding: 10px 15px;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      padding: 5px 0;
      border-radius: 12px;
      box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
      .list {
        padding: 0px 5px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        &__item {
          display: block;
          padding: 12px 15px;
          @include type();
          background-color: #fff;
          cursor: pointer;
          border-radius: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover,
          &--active {
            background-color: $blueLight;
            color: $blue;
          }
          &--status {
            padding-left: 35px;
            &_Inactive,
            &_Active,
            &_Published,
            &_Draft {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: -25px;
                transform: translateY(-50%);
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 12px;
              }
            }
            &_Inactive:after {
              background-color: $light3Grey;
            }
            &_Draft:after {
              background-color: $orange;
            }
            &_Active,
            &_Published {
              &:after {
                background-color: $green;
              }
            }
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          a {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }
}

.switcher {
  margin-left: 10px;
  &__label {
    min-width: 0 !important;
    cursor: pointer;
    width: 32px;
    height: 22px;
    background: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    border-radius: 30px;
    position: relative;
    margin-bottom: 0 !important;
    span {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0;
      z-index: 2;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      //@include transition();
      transition: all 0.3s ease;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.3s;
      left: 2px;
      transform: translateY(-50%);
    }
    &--actiive {
      background: $green;
      &:after {
        left: calc(100% - 2px);
        transform: translateX(-100%) translateY(-50%);
      }
    }
  }
}
</style>
