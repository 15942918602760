<template>
  <div
    class="form-control"
    :class="{ 'form-control--disabled': disabled, 'form-control--label-left': labelLeft }"
  >
    <h3 v-if="labelLeft">{{ placeholder }}</h3>
    <div class="form-control__input">
      <div class="switcher" @click="disabled ? false : $emit('input', !selected)">
        <label
          class="switcher__label"
          :class="{ 'switcher__label--disabled': disabled, 'switcher__label--actiive': selected }"
        >
          <span> </span>
          <span> </span>
        </label>
      </div>
    </div>
    <h3 v-if="!labelLeft">{{ placeholder }}</h3>
  </div>
</template>
<script>
export default {
  props: {
    /* items: { type: Array }, */
    selected: { type: [Boolean, Number, String], default: false },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    labelLeft: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  display: flex;
  align-items: center;
  padding: 5px 0;
  h3 {
    @include type($fs: 1.85rem, $fw: 600);
    color: $black;
    margin: 0 20px 0 10px;
  }
  &__input {
    &:-webkit-autofill {
      background-color: rgba(255, 255, 255, 1) !important;
      background-image: none !important;
      -webkit-box-shadow: 0 0 0px 10em white inset !important;
      -webkit-text-fill-color: $black !important;
    }
    &::placeholder {
      color: $white;
      opacity: 0;
    }
    &:-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &::-ms-input-placeholder {
      color: $white;
      opacity: 0;
    }
    &:disabled {
      opacity: 0.6;
    }
  }
  &__input:focus ~ &__label,
  &__input:valid ~ &__label,
  &__input:not(:placeholder-shown) ~ &__label {
    top: 0;
    width: auto;
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
  &--invalid {
    .form-control__select {
      border: 1px solid $error;
      color: $error;
    }
  }
  &--label-left {
    h3 {
      margin-right: 20px;
    }
  }
  /* .icon {
    display: inline-flex;
    width: 1.85rem;
    height: 1.85rem;
    transition: all 0.3s ease-in-out;
    transform: rotate(-180deg);
    &--open {
      transform: rotate(0);
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 14px;
      height: 14px;
    }
  } */
  /* &__items {
    width: calc(100% + 30px);
    position: absolute;
    left: -15px;
    bottom: 0;
    transform: translate(0, 100%);
    z-index: 2;
    padding: 10px 15px;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-color: #fff;
    &--visible {
      visibility: visible;
      opacity: 1;
      max-height: 500px;
    }
    &_body {
      padding: 5px 0;
      border-radius: 12px;
      box-shadow: 0 2px 12px rgba($color: $black, $alpha: 0.25);
      .list {
        padding: 0px 5px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 280px;
        &__item {
          display: block;
          padding: 12px 15px;
          @include type();
          background-color: #fff;
          cursor: pointer;
          border-radius: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover,
          &--active {
            background-color: $blueLight;
            color: $blue;
          }
          &--status {
            padding-left: 35px;
            &_Inactive,
            &_Active,
            &_Published,
            &_Draft {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: -25px;
                transform: translateY(-50%);
                display: inline-block;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                margin-right: 12px;
              }
            }
            &_Inactive:after {
              background-color: $light3Grey;
            }
            &_Draft:after {
              background-color: $orange;
            }
            &_Active,
            &_Published {
              &:after {
                background-color: $green;
              }
            }
          }
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          a {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  } */
}

.switcher {
  &__label {
    min-width: 0 !important;
    cursor: pointer;
    width: 32px;
    height: 22px;
    background: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #efefef;
    border-radius: 30px;
    position: relative;
    margin-bottom: 0 !important;
    &--disabled {
      cursor: default;
    }
    span {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0;
      z-index: 2;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      //@include transition();
      transition: all 0.3s ease;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.3s;
      left: 2px;
      transform: translateY(-50%);
    }
    &--actiive {
      background: $green;
      &:after {
        left: calc(100% - 2px);
        transform: translateX(-100%) translateY(-50%);
      }
    }
  }
}
</style>

