<template>
  <div class="cards">
    <template v-if="items.length">
      <div class="cards__item" v-for="i in items" :key="i.id">
        <div
          class="cards__item_img"
          :class="{ 'cards__item_img--view': i.view }"
          :style="{ backgroundImage: i.preview_image ? `url(${i.preview_image})` : '' }"
        >
          <IconButton
            v-if="i.view"
            class="button--white button--md"
            :disabled="i.status === 'inactive' || i.status === 'draft'"
            @click="$emit('clickAction', { key: 'view', id: i.id, item: i })"
          >
            <IconEye></IconEye>
          </IconButton>
        </div>
        <div class="cards__item_content">
          <div class="cards__item_content_left">
            <h6 class="cards__item_content_title">{{ i.title }}</h6>
            <p class="cards__item_content_subtitle">{{ i.subtitle }}</p>
          </div>
          <div class="cards__item_content_right">
            <span
              class="cards__item_content_status"
              :class="`cards__item_content_status--${i.status}`"
            ></span>
            <span v-if="i.tag" class="cards__item_content_tag">{{ i.tag }}</span>
            <span
              v-if="i.edit"
              class="cards__item_content_icon"
              @click="$emit('clickAction', { key: 'edit', id: i.id, item: i })"
            >
              <IconEdit></IconEdit>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="cards__empty">NO DATA</p>
    </template>
  </div>
</template>
<script>
import IconEdit from "@/components/icons/IconEdit.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconEye from "@/components/icons/IconEye.vue";
export default {
  props: {
    items: { type: Array },
  },
  components: { IconEdit, IconButton, IconEye },
};
</script>
<style lang="scss" scoped>
.cards {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid rgba($color: #979797, $alpha: 0.3);
  border-radius: 16px;
  padding: 25px 15px;
  background-color: $white;
  flex: 1;
  &--side {
    &-three {
      .cards__item {
        padding: 7px;
        width: calc((100% - 60px) / 4);
        @media only screen and (max-width: $xl) {
          width: calc((100% - 40px) / 3);
        }
        @media only screen and (max-width: $lg) {
          width: calc((100% - 20px) / 2);
        }
        &_content_title {
          min-height: 50px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
        }
      }
    }
  }
  &__item {
    width: calc((100% - 60px) / 3);
    border: 1px solid rgba($color: #979797, $alpha: 0.3);
    border-radius: 16px;
    padding: 10px;
    margin: 0 10px 20px;
    @media only screen and (max-width: $xl) {
      width: calc((100% - 40px) / 2);
    }
    &_img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 25rem;
      background-color: rgba($color: #979797, $alpha: 0.3);
      background-size: cover;
      background-position: center;
      border-radius: 16px;
      margin-bottom: 15px;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 16px;
        transition: all 0.3s ease;
      }
      .button {
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        transition: all 0.3s ease;
      }
      &:hover {
        &:after {
          background: rgba(0, 0, 0, 0.41);
        }
        .button {
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
      }
    }
    &_content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &_left {
        max-width: 50%;
      }
      &_right {
        display: flex;
        align-items: center;
        padding-top: 2px;
        margin-left: 5px;
        max-width: 50%;
      }
      &_title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $black;
      }
      &_subtitle {
        font-size: 14px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $black;
        @media only screen and (max-width: $lg) {
          font-size: 12px;
        }
      }
      &_icon {
        cursor: pointer;
        &::v-deep g {
          fill: $black;
          transition: all 0.3s ease-in-out;
        }
        &:hover {
          &::v-deep g {
            fill: $orange;
          }
        }
      }
      &_status {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        margin-right: 12px;
        &--inactive {
          background-color: $light3Grey;
        }
        &--draft,
        &--upcoming {
          background-color: $yellow;
        }
        &--active,
        &--published,
        &--live {
          background-color: $green;
        }
        &--completed {
          background-color: $error;
        }
      }
      &_tag {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.25;
        color: $blue;
        text-transform: lowercase;
        white-space: nowrap;
        padding: 6px 10px;
        margin-right: 10px;
        border-radius: 6px;
        background-color: $blueLight;
      }
    }
  }
  &__empty {
    flex: 1;
    padding: 50px 20px 0;
    @include type($fw: 600);
    text-align: center;
    color: $black;
  }
}
</style>
