<template>
  <div class="filter" v-click-outside="hideFilter">
    <header class="filter__header">
      <p class="text">Filter by</p>
      <IconButton class="button--admin button--outline-grey button--md" @click="open = true">
        <IconFilter></IconFilter>
      </IconButton>
    </header>
    <div class="filter__body" v-if="open" id="scroll-block">
      <div class="filter__group filter__group--header">
        <p class="text">Filter</p>
        <IconButton class="button--admin button--md" @click="open = false">
          <IconClose></IconClose>
        </IconButton>
      </div>
      <ValidationObserver ref="observer" tag="div" class="filter__body_content">
        <div class="filter__group" v-for="item in items" :key="item.group">
          <div class="filter__group_header">
            <p class="text">{{ item.group }}</p>
            <TogglePlusMinus :value="item.open" @click="item.open = !item.open"></TogglePlusMinus>
          </div>
          <div
            class="filter__group_body filter__group_body--open-xl"
            :class="{ 'filter__group_body--open': item.open }"
          >
            <template v-if="item.type === 'search'">
              <MultipleAutocompleteInput
                class="autocomplete-input--xs autocomplete-input--relative"
                style="margin-bottom: 15px"
                :id="`autocomplete-${item.key}`"
                v-model="searchType[item.key]"
                :creating="false"
                :focusOpen="true"
                :loading="loadings[item.key]"
                :items="searchType[item.key]"
                :matches="matches[item.key]"
                :placeholder="`Search ${item.group}`"
                @input="
                  searchType[item.key] = $event;
                  $emit('selectFilter', { key: item.key, searched: $event });
                "
                @searchMatches="$emit('searchMatches', { key: item.key, event: $event })"
              ></MultipleAutocompleteInput>
            </template>
            <template v-else-if="item.type === 'range'">
              <div class="filter__group_body-row" style="margin-bottom: 25px">
                <ValidationProvider
                  v-for="(i, id) in item.items"
                  :key="id"
                  tag="div"
                  :rules="{
                    more_then: i.key === 'price_start' ? price.price_end : false,
                    less_then: i.key === 'price_end' ? price.price_start : false,
                  }"
                  v-slot="{ errors }"
                >
                  <BaseNumberInput
                    class="form-control--xxs"
                    :placeholder="i.title"
                    :validateError="errors[0]"
                    symbol="$"
                    v-model="price[i.key]"
                    :element="price[i.key]"
                    @input="priceChange({ i, event: $event })"
                  />
                </ValidationProvider>
              </div>
            </template>
            <template v-else>
              <Tag
                class="tag--capitalize tag--xs"
                :class="{ 'tag--selected': i.selected }"
                v-for="(i, idx) in item.items"
                :key="`${i.title}-${idx}`"
                :value="i.title"
                @click="$emit('selectFilter', { key: item.key, filter: i })"
              />
            </template>
          </div>
        </div>
      </ValidationObserver>
      <div class="filter__group filter__group--buttons">
        <BaseButton
          class="button--sm button--outline button--uppercase"
          text="clear"
          @click="$emit('clear')"
        ></BaseButton>
        <BaseButton
          class="button--sm button--black button--uppercase"
          text="apply"
          @click="$emit('apply')"
        ></BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import IconButton from "@/components/buttons/IconButton.vue";
import TogglePlusMinus from "@/components/buttons/TogglePlusMinus.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconClose from "@/components/icons/IconClose.vue";
import Tag from "@/components/Tag.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import ClickOutside from "vue-click-outside";

export default {
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    matches: { type: Object },
    loadings: { type: Object },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    IconButton,
    TogglePlusMinus,
    BaseButton,
    IconFilter,
    Tag,
    IconClose,
    MultipleAutocompleteInput,
    BaseNumberInput,
  },
  data() {
    return {
      open: false,
      searchType: {
        materials: [],
      },
      price: {
        price_start: "",
        price_end: "",
      },
    };
  },
  watch: {
    open(val) {
      this.$emit("open", val);

      this.items.forEach(el => {
        if (el.group === "Price") {
          el.items.forEach(element => {
            this.price[element.key] = element.selected ? element.val : "";
          });
        }
      });
    },
    items: {
      handler: function (val, oldVal) {
        this.baseSetup(val);
      },
      deep: true,
    },
  },
  methods: {
    baseSetup(val) {
      val.forEach(el => {
        if (el.group === "Price") {
          el.items.forEach(element => {
            this.price[element.key] = element.val;
          });
        }
        if (el.group === "Materials") {
          this.searchType.materials = el.items.filter(i => {
            return i.selected;
          });
        }
      });
    },
    async priceChange({ i, event }) {
      this.price[i.key] = event;

      let valid = await this.priceValidation();

      this.$emit("selectFilter", {
        key: i.key,
        filter: i,
        val: event,
        valid,
      });
    },
    priceValidation() {
      let valid = false;
      if (Number(this.price.price_end) && Number(this.price.price_start)) {
        valid = Number(this.price.price_start) < Number(this.price.price_end) ? true : false;
      } else valid = true;

      return valid;
    },
    hideFilter() {
      this.open = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss" scoped>
.filter {
  position: relative;
  .text {
    @include type($fw: 700);
    margin-right: 2rem;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
  }
  &__body {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -1px;
    right: -2px;
    transform: translate(0%, 0%);
    min-width: 45rem;
    max-width: 45rem;
    height: 57rem;
    background-color: $white;
    border-radius: 12px;
    box-shadow: 0 2px 16px rgba($color: $black, $alpha: 0.25);
    z-index: 3;
    @media (max-width: $xxl) {
      right: auto;
      left: 0;
    }
    &_content {
      overflow-y: auto;
    }
  }
  &__group {
    border-bottom: 0.5px solid #979797;
    padding: 0 0 0.3rem;
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.4rem 1.3rem 1.3rem 2rem;
    }
    &--buttons {
      display: flex;
      border: none;
      padding: 2.4rem 2rem;
      margin: auto 0 0;
      .button {
        margin: 0 1.1rem;
      }
    }
    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem 1.3rem 1.3rem 2rem;
    }
    &_body {
      display: flex;
      flex-wrap: wrap;
      margin: 0 1.3rem 0 1.5rem;
      max-height: 0;
      overflow-y: auto;
      transition: all 0.3s ease;
      &--open {
        max-height: 30rem;
        &.filter__group_body--open-xl {
          max-height: 40rem;
        }
      }
      &-row {
        display: flex;
        margin-bottom: 15px;
        div:first-of-type {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
